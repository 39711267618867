























import {
  defineComponent,
  PropType,
  ref,
  computed
} from '@nuxtjs/composition-api';
import { SfHeading } from '@storefront-ui/vue';
import { useCustomOptions } from '@wemade-vsf/catalog/lib/runtime/product/composables/useCustomOptions';
import type { CustomizableMultipleOption } from '@wemade-vsf/magento-api';

export default defineComponent({
  props: {
    option: {
      type: Object as PropType<CustomizableMultipleOption>,
      required: true
    },
    productPrice: {
      type: Number,
      default: 0
    }
  },
  components: {
    SfHeading
  },
  setup (props) {
    const { getTitleWithPrice, calculatePrice, optionSelected } = useCustomOptions();
    const optionValues = ref<string[]>([]);

    const optionLabel = computed(() => (option) =>{
      return getTitleWithPrice({
        productPrice: props.productPrice,
        optionPrice: option.price || 0,
        title: option.title || '',
        priceType: option.price_type
      })
    })

    const price = computed(() => {
      const options = props.option.multiple?.filter(c => c?.uid && optionValues.value.indexOf(c.uid) > -1)
      if (!options?.length) return 0
      let price = 0;
      options.forEach(option => {
        const optionPrice = calculatePrice({
          productPrice: props.productPrice,
          optionPrice: option?.price || 0,
          priceType: option?.price_type
        })
        price = optionPrice ? price + optionPrice : price
      })
      return price;
    })
    
    const updateSelection = () => {
      optionSelected({ uid: props.option.uid, value: optionValues.value, price: price.value })
    }

    return {
      optionValues,
      optionLabel,
      updateSelection
    }
  }
})
